import React from 'react'

export default function profile(A) {
    const person = JSON.parse(A)
    return (
          <div
            className="box"
            style={{ paddingTop: "20px", paddingBottom: "20px" , paddingLeft:"20px", paddingRight:"20px"}}
          >
            <img
              alt="img"
              className="rounded-circle"
              src={person.photoLine}
              style={{ width: "118px", height: "148px" }}
            />
            <h3
              className="name"
              style={{
                marginTop: "14px",
                fontWeight: 300,
                marginBottom: "2px",
                color: "#f3b00c"
              }}
            >
              {person.name}
            </h3>
            <p
              className="title"
              style={{ marginBottom: "2px", color: "#f3b00c" }}
            >
              {person.intro}
            </p>
            {/* <div className="social" style={{ marginBottom: "5px" }}>
              <a
                href={
                  "https://api.whatsapp.com/send?phone=" +
                  person.waNumber +
                  "&text=Hi+5amier"
                }
              >
                <span
                  style={{
                    color: "rgb(13,13,13)",
                    marginLeft: "0px",
                    marginRight: "5px"
                  }}
                >
                  {person.waNumber}
                </span>
                <i
                  className="fa fa-whatsapp"
                  style={{ color: "var(--success)" }}
                />
              </a>
            </div> */}
            <div>
              <a
                className="btn btn-primary"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls={"a" + person.waNumber}
                href={"#" + "a" + person.waNumber}
                role="button"
                style={{
                  background: "rgb(255,148,23)",
                  borderWidth: "0px",
                  borderRadius: "7px",
                  marginBottom: "2px",
                }}
              >
                Toggle Info
              </a>
              <div className="collapse" id={"a" + person.waNumber} style={{alignSelf:'center'}}>
                <div style={{ paddingTop: "15px", textAlign: "left" }}>
                  <span style={{ color: "#f3b00c" }}>
                    Scribble space to express yourself:&nbsp;
                  </span>
                  <p>{person.scribbleSpace}</p>
                  <span style={{ color: "#f3b00c" }}>
                    Contribution to know what you can offer to others in coming
                    21 days:
                  </span>
                  <p>{person.contribution}</p>
                  <span style={{ color: "#f3b00c" }}>From</span>
                  <p>{person.place}</p>
                </div>
              </div>
            </div>
          </div>
    )
}
