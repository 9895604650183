import React from "react";
import "./styles.css";
import Nav from "./navbar";
import Row from "./row";
import * as data from "./data"; //database
import profile from "./profile";

export default function App() {
  return (
    <div className="App" style={{width:"100%", alignItems:"center"}}>
      {Nav()}
      <h2
        className="display-2"
        style={{
          fontSize: "32px",
          textAlign: "left",
          color: "#f3b00c",
          marginTop: "5px",
          paddingLeft: "15px",
          paddingRight: "25px"
        }}
      >Certifcate of 5'AMers | June 2021</h2>
      
      <div style={{width:"100%", alignItems:'center'}}>
      {data.data.map((person)=>{
        // console.log(person)
        return(profile(person))
      })}
      </div>
      </div>
  );
}
